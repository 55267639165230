.navigation {
  font-size: 1.8rem;
  line-height: 28px;
  color: $color-4;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  li{

    margin: 10px;
    display: inline;
  }

  &:before {

    content: '';
    position: absolute;
    height: 150px;
    width: 300px;
    border-radius: 20%;
    background: $color-3;
    right: -10%;
    bottom: 2%;
    z-index: -1;
    animation: navAnim 20s infinite alternate;

    @keyframes navAnim {
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .nav-active {
    color: $black;
  }
  ul:hover li:not(:hover) {
    opacity: .5;
  }
  ul:hover li:hover {
    color: $black;
  }

}