
.loginWrapper {
  position: absolute;
  display: flex;
  text-align: center;
  background-image: linear-gradient(276deg, #49a09d, #5f2c82);
  background-size: cover;
  padding: 7%;
  box-shadow: rgb(24 26 27) 0 0 25px 2px inset, rgb(0 0 0) 10px 10px 10px;
  border-color: rgba(140, 130, 115, 0.5);
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 4%;
  transition: all 1s;
  .errorSpan{
    transition: all 0.5s;

    padding: 5%;
    margin: 5%;
    background-color: indianred;
    border-radius: 4%;
  }

  input{
    padding: 2%;
    margin: 10%;
    border: 2px black solid
  }
}