@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/Questrial-Regular.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/KronaOne-Regular.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", serif;

$color-1: #fafeff;
$color-2: #00c1ec;
$color-3: #d2ffff;
$color-4: rgb(141, 141, 141);
$black: rgb(40, 40, 40);
$red: rgb(253, 87, 87);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  &::selection {
    color: $color-1;
    background: $color-2;
  }
}
html { 
  scroll-behavior: unset;
}
a,
a:visited {
  color: $color-4;
}
body {
  font-family: $font-1;
  height: 100vh;
  background-color: $color-1;
  color: $black;
  overflow-x: hidden;
  overflow-y: initial;
  @media screen and (max-width: 430px){
    position: relative;
    max-width: 100vw;
  }
}
main {
  position: relative;
}
.button {
  background: $color-3;
  height: 55px;
  width: 126px;
  border-radius: 50px;
  margin-top: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    letter-spacing: 1px;
    color: $color-2;
  }
}
.table {
  @media screen and (max-width: 500px){
    overflow-x: auto;
    display: block;
    white-space: nowrap;
    width: 100%;
  }
  @media screen and (max-width: 800px) and (min-width: 501px) {
    width: 100%;
  }

  top: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
  width: 800px;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);

  th,
  td {
    padding: 15px;
    background-color: rgba(255,255,255,0.2);
    color: #fff;
  }

  th {
    text-align: left;
  }

  thead {
    th {
      background-color: #55608f;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    td {
      position: relative;

      &:hover {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: -1;
        }
      }
    }
  }
}
