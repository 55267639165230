.dynamic-text {
  position: relative;
  display: flex;
  @media screen and (max-width: 885px){
    display: block;

  }
  .simply {
    color: $color-4;
  }

  #text-target {
    display: flex;
    width: 300px;
    text-align: left;
    animation: color 30s alternate infinite;
    perspective: 200px;
    transform: translateX(26px);
    @media screen and (max-width: 885px){
      transform: none;
    }

  }
  .word {
    position: absolute;
  }
  @keyframes cursor {
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes color {
    0% {
      color: $color-2;
    }
    50% {
      color: $color-4;
    }
    100% {
      color: $color-2;
    }
  }
}
