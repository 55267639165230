.scroll-bottom {
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  color: $color-4;
  font-size: 1.7rem;
  perspective: 40px;
  transition: visibility 1s, opacity 0.5s linear;
  .button-container {
    width: 130px;

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      .button {
        padding: 2%;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-1;
        background: $color-2;



        &:hover {
          background: $red;
        }
      }
    }
  }
}
