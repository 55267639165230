.projets{
  //border: 1px solid red;
  //border-radius: 2%;
  left: 50%;

  text-align: center;

  height: 50rem;
  h1{
    height: 80%;
    line-height: 45rem;
  }
}