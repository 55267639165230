.backoffice{
  color: white;
  text-align: center;
  a{
    color: white;
    font-weight: bold;
  }
  h1, h2{
    margin: 2%;
  }
  table{
    table-layout: fixed;

  }
  th{
    text-align: center;
  }
  .buttonTable{
    display: flex;
    flex-direction: column;
    button{
      border-radius: 18px;
      border: solid 1px transparent;
      cursor: pointer;
      padding: 5%;
      margin: 2%;
      color: white;
    }
  }
  .backButton{
    position: absolute;
    left: 2%;
  }
}
