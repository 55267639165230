
.buyPage {
  font-family: sans-serif;
  font-weight: 100;
  h1{
    padding: 3%;
  }
  .price{
    background-color: rgba(255, 255, 255, .1);
    margin: 2% 40% 2% 40%;

  }
  h1, h2 {
    text-align: center;
    color: #fff;
  }
  .paypal{
    text-align: center;
    background-color: rgba(255, 255, 255, .1);
    padding: 3%;
    margin: 0 20% 0 20%;
  }
}
