.logo {
  position: absolute;
  font-family: $font-2;
  font-size: 1.2rem;
  user-select: none;
  left: 10%;
  top: 1.2rem;
  z-index: 1;
  @media screen and (max-width: 840px){
    text-indent: -99999px;
  }
  &:after {
    content: "";
    height: 24px;
    width: 24px;
    border: 1px $color-2 solid;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: -12px;
    z-index: -1;
    @media screen and (max-width: 840px){
      border: none;
      background: url(../../assets/img/logo.svg);
      background-size: 24px 24px;
    }
  }
}